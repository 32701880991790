import { ColorPalette } from "interfaces" 

export const LoncelColorPalette: ColorPalette = {
    BRAND_PRIMARY: '#9c29b7',
    MARKER_COLOR: 'purple'
}

export const FrostColorPalette: ColorPalette = {
    BRAND_PRIMARY: '#005BA3',
    MARKER_COLOR: '#005BA3'
}