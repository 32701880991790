/**
 * @file index.ts
 * @description Mani utility file of loncel-brand-kit.
 * @author Dushan Ranasinghage
 * @copyright 2023, Loncel Technologies 2014 Ltd, All Rights Reserved
 */

import getLogo from './logo';
import {
  HtmlWebpackConfig,
  ManifestConfig,
  ApplicationType,
  ThemeType,
  ColorPalette,
} from './interfaces';
import { FrostColorPalette, LoncelColorPalette } from './utils';
const ncp = require('ncp').ncp;
const path = require('path');

const theme: ThemeType = process.env.FROST ? 'frost' : 'loncel';

/**
 * This function returns the application name according to the theme set on env.
 *
 * @returns {string} returns the application name
 */
export const getAppName = (application: ApplicationType): string => {
  let appName: string = '';

  switch (application) {
    case 'cloud':
      switch (theme) {
        case 'frost':
          appName = 'FrostSmart';
          break;
        case 'loncel':
          appName = 'Loncel Technologies';
          break;
        default:
          appName = 'Loncel Technologies';
          break;
      }
      break;
    case 'status':
      switch (theme) {
        case 'loncel':
          appName = 'Loncel Status';
          break;
        case 'frost':
          appName = 'Status';
          break;
        default:
          appName = 'Status';
          break;
      }
      break;
    default:
      appName = 'App name';
      break;
  }
  return appName;
};

/**
 * This function returns the application short name according to the theme set on env.
 *
 * @returns {string} returns the application short name
 */
export const getAppShortName = (application: ApplicationType): string => {
  let appName: string = '';

  switch (application) {
    case 'cloud':
      switch (theme) {
        case 'frost':
          appName = 'FrostSmart';
          break;
        case 'loncel':
          appName = 'Loncel';
          break;
        default:
          appName = 'Loncel';
          break;
      }
      break;
    case 'status':
      switch (theme) {
        case 'loncel':
          appName = 'Loncel Status';
          break;
        case 'frost':
          appName = 'FrostSmart Status';
          break;
        default:
          appName = 'Loncel Status';
          break;
      }
      break;
    default:
      appName = 'App Short Name';
      break;
  }
  return appName;
};

/**
 * This function returns the HtmlWebpackConfig that is needed
 * for the application webpack configuration according to the theme set on env.
 *
 * @returns {HtmlWebpackConfig} return the HtmlWebpackConfig for the application webpack config
 */
export const htmlWebpackConfig = (
  application: ApplicationType
): HtmlWebpackConfig => {
  const commonConfig: HtmlWebpackConfig = {
    title: 'Loncel Cloud',
    template: 'src/index.html',
    baseUrl: './',
    inject: true,
  };

  switch (application) {
    case 'cloud':
      switch (theme) {
        case 'frost':
          return { ...commonConfig, title: 'FrostSmart' };
        case 'loncel':
          return commonConfig;
        default:
          return commonConfig;
      }
    case 'status':
      switch (theme) {
        case 'frost':
          return {
            ...commonConfig,
            template: 'public/index.html',
            title: 'FrostSmart Status',
          };
        case 'loncel':
          return {
            ...commonConfig,
            template: 'public/index.html',
            title: 'Loncel Status',
          };
        default:
          return {
            ...commonConfig,
            template: 'public/index.html',
            title: 'Loncel Status',
          };
      }
    default:
      return commonConfig;
  }
};

/**
 * This function returns the ManifestConfig that is needed
 * for the application webpack configuration according to the theme set on env.
 *
 * @returns {ManifestConfig} return the ManifestConfig for the application webpack config
 */
export const manifestConfig = (
  application: ApplicationType
): ManifestConfig => {
  const commonConfig: ManifestConfig = {
    short_name: 'LCloud',
    name: 'Loncel Cloud',
    start_url: '/',
    display: 'standalone',
  };

  switch (application) {
    case 'cloud':
      switch (theme) {
        case 'frost':
          return { ...commonConfig, short_name: 'FSmart', name: 'FrostSmart' };
        case 'loncel':
          return commonConfig;
        default:
          return commonConfig;
      }
    case 'status':
      switch (theme) {
        case 'frost':
          return { ...commonConfig, short_name: 'FSmart', name: 'FrostSmart' };
        case 'loncel':
          return { ...commonConfig, short_name: 'FSmart', name: 'FrostSmart' };
        default:
          return { ...commonConfig, short_name: 'FSmart', name: 'FrostSmart' };
      }
    default:
      return commonConfig;
  }
};

/**
 * This function return copies all the theme related icons and manifest files from
 * the brand library to application's public folder
 *
 * @param application ApplicationType = "cloud" | "status"
 */
export const cloneBrandAssets = (application: ApplicationType): void => {
  if (application == 'status') {
    const brandAssetsPath: string = `node_modules/@frostsmart/loncel-brand-kit/dist/logo/${application}/${theme}`;
    const targetPath: string = 'public';

    ncp(brandAssetsPath, targetPath, function (err) {
      if (err) {
        console.error(err);
      } else {
        console.log('Files copied successfully');
      }
    });
  } else if (application == 'cloud') {
    const faviconPath: string = `node_modules/@frostsmart/loncel-brand-kit/dist/logo/${application}/${theme}/favicon`;
    const manifestPath: string = `node_modules/@frostsmart/loncel-brand-kit/dist/logo/${application}/${theme}/manifest`;
    const faviconTargetPath: string = 'src/img';
    const manifestTargetPath: string = 'src';

    ncp(faviconPath, faviconTargetPath, function (err) {
      if (err) {
        console.error(err);
      } else {
        console.log('Favicon copied successfully');
      }
    });

    ncp(manifestPath, manifestTargetPath, function (err) {
      if (err) {
        console.error(err);
      } else {
        console.log('Manifest copied successfully');
      }
    });
  } else {
    console.log('No matching application to copy assets');
  }
};

/**
 * This function return the relavant color palette according to the theme
 *
 * @returns ColorPalette
 */
const getColorPalette = (): ColorPalette => {
  switch (theme) {
    case 'frost':
      return FrostColorPalette;
    case 'loncel':
      return LoncelColorPalette;
    default:
      return LoncelColorPalette;
  }
};

export { getLogo, getColorPalette };
