/**
 * @file logo/index.ts
 * @description This module decides which logo should be served according to the theme
 * @author Dushan Ranasinghage
 * @copyright 2023, Loncel Technologies 2014 Ltd, All Rights Reserved
 */

import { ApplicationType, ThemeType, IconType, applicationThemes, theme } from '../interfaces'

// Cloud application logos
import cloudFrostFavicon from './cloud/frost/favicon/favicon.ico';
import cloudFrostFlatLogo from './cloud/frost/logo_thumb.png';
import cloudFrostNameLogo from './cloud/frost/logo_login.png';

import cloudLoncelFavicon from './cloud/loncel/favicon/favicon.ico';
import cloudLoncelFlatLogo from './cloud/loncel/logo_thumb.png';
import cloudLoncelNameLogo from './cloud/loncel/logo_login.png';

// Status application logos
import statusLoncelFavicon from './status/loncel/favicon.ico';
import statusLoncelFlatLogo from './status/loncel/logo_thumb.png';

import statusFrostFavicon from './status/frost/favicon.ico';
import statusFrostFlatLogo from './status/frost/logo_thumb.png';

const theme: ThemeType = process.env.FROST ? 'frost' : 'loncel';

const applicationThemes: applicationThemes = {
    cloud: {
        frost: {
            favicon: cloudFrostFavicon,
            flatLogo: cloudFrostFlatLogo,
            nameLogo: cloudFrostNameLogo
        },
        loncel: {
            favicon: cloudLoncelFavicon,
            flatLogo: cloudLoncelFlatLogo,
            nameLogo: cloudLoncelNameLogo
        },
    },
    status: {
        frost: {
            favicon: statusFrostFavicon,
            flatLogo: statusFrostFlatLogo,
            nameLogo: null
        },
        loncel: {
            favicon: statusLoncelFavicon,
            flatLogo: statusLoncelFlatLogo,
            nameLogo: null
        },
    },
}

/**
 * This function return the logo according to the theme selected and the input given
 *
 * @param {iconType} string = This iconType can be "favicon", "flatLogo", or "nameLogo"
 * @returns {string} returns the logo byte stream
 */
const getLogo = (application: ApplicationType, iconType: IconType): string => {

    switch (application) {
        case 'cloud':
            return applicationThemes[application][theme][iconType];
        case 'status':
            return applicationThemes[application][theme][iconType];
        default:
            return null;
    }
};

export default getLogo